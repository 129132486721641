<template>
  <Modal v-model="showModal" :mask-closable="false" :width="item.width">
    <div slot="header">设备状态信息</div>
    <Form ref="form" label-position="top">
      <FormItem prop="name" label="通信ID" style="margin-right: 15px">
        <span class="form-item-content">{{ item.code }}</span>
      </FormItem>
      <FormItem prop="name" label="设备状态" style="margin-right: 15px">
        <span class="form-item-content">{{ item.name }}</span>
      </FormItem>
      <FormItem prop="statusTime" label="更新时间" style="margin-right: 15px">
        <span class="form-item-content">{{ item.statusTime }}</span>
      </FormItem>
      <FormItem prop="statusRemark" label="更新说明" style="margin-right: 15px">
        <span class="form-item-content">{{ item.statusRemark }}</span>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button type="primary" v-if="item.status == 2" :loading="loading" @click="setTest">转为测试</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalEditArgs',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
  },
  methods: {
    setTest: function () {
      this.$Modal.confirm({
        title: "库存转测试",
        content: "确定要将此设备转为待测试设备吗？",
        onOk: async () => {
          this.$axios.post(`site/debug/SetLightTest`, { code: this.item.code }).then(res => {
            if (res.code == 0) {
              this.$Message.info('转换成功');
              this.$emit('saved');
              this.showModal = false;
              // this.$Modal.info({title: '系统提示', content:'转换成功', onOk: function(){
              //   // this.$emit('saved');
              //   // this.showModal = false;
              //   console.log('on modal ok')
              // } });
            }
          })
        },
      });
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-content{
  font-weight: bolder;
  font-size: 30px;
}
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>