<template>
  <Modal v-model="showModal" :mask-closable="false" :width="item.width">
    <div slot="header">设备{{item.title}}信息</div>
    <Form ref="form" label-position="top">
      <FormItem prop="name" label="设备列表" style="margin-right: 15px">
        <div class="form-item-content">{{ item.codes && item.codes.join(', ') }}</div>
      </FormItem>
      <FormItem prop="name" label="备注" style="margin-right: 15px">
        <Input v-model="comment" maxlength="100" :rows="5" show-word-limit type="textarea" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalEditArgs',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      comment: '',
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.comment = '';
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
  },
  methods: {
    ok: function () {
      this.comment = this.comment.trim();
      if(this.comment.length == 0){
        this.$Message.warning('请输入备注信息');
        return;
      }
      this.$Modal.confirm({
        title: this.item.title,
        content: `确定要将这些设备${this.item.title}吗？`,
        onOk: async () => {
          this.$emit('saved', {codes: this.item.codes, comment: this.comment, cmd: this.item.cmd});
          this.showModal = false;
        },
      });
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-content{
  border: solid 1px rgba(129, 124, 124, 0.377);
  font-weight: bolder;
  font-size: 20px;
  max-height: 300px;
  overflow-y: auto;
}
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>